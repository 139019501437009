ul {
    list-style-type: none;
    margin: auto;
    padding: 0;
    background-color: #333;
    text-align: center;
}

li {
    display: inline;
}

li a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 10px 16px;
    text-decoration: none;
    width: 70px;
}

li a:hover {
    background-color: #111;
}

.active {
    color: black;
}