#leaderboard {
    border: solid 1px;
    border-collapse: collapse;
}

#leaderboard caption {
    padding-bottom: 30px;
}

#leaderboard thead th {
    border: solid 1px;
    padding: 10px;
}

#leaderboard tbody td {
    border: solid 1px;
    padding: 10px;
}

.currentUser {
    background-color: gold;
}