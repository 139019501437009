table {
  margin: auto;
}

#question {
  font-size: 8em;
  transition: all 0.2s;
}

#main span {
  display: inline-block;
  font-size: 3em;
  background-color: #434343;
  color: white;
  cursor: pointer;
  line-height: 100px;
  width: 100px;
  margin: 0;
}

#main #btn0 {
  width: 250px;
}

#counter {
  font-size: 2em;
  padding-top: 15px;
}

.incorrect {
  color: red;
}